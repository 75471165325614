<template>
  <div class="login_user">
    <div class="input_box">
      <div class="title">手机号</div>
      <input type="number" v-model="formData.phone" placeholder="请输入手机号" />
    </div>
    <div class="input_box">
      <div class="title">密码</div>
      <input type="password" v-model="formData.password" placeholder="请输入密码" />
    </div>
    <!-- <div class="wjmm">忘记密码</div> -->
    <button @click="getOut">立即登录</button>
    <div class="tips">登录及接受 <span>用户协议</span> 及 <span>隐私授权</span></div>
  </div>
</template>

<script>
import { loginApi } from "@/api/getData";
import { useRouter } from "vue-router";
import { reactive, toRefs,onMounted } from "vue";
import { showNotify } from "vant";
export default {
  setup() {
    const obj = reactive({
      formData: {
        phone: "",
        password: "",
      },
    });
    const router = useRouter();
    const getOut = async () => {
      if (pdNull()) {
        return false;
      }
      try {
        let res = await loginApi(obj.formData);
        if (res.data.code == 200) {
          let data = JSON.stringify(res.data.data);
          localStorage.setItem("token", data);
          router.push("/home");
        } else {
          showNotify({
            message: res.data.msg,
            className: "MySize",
          });
        }
      } catch (error) {
        showNotify({
          message: error,
          className: "MySize",
        });
      }
    };
    const pdNull = () => {
      if (
        obj.formData.phone == undefined ||
        obj.formData.password == undefined ||
        obj.formData.phone == "" ||
        obj.formData.password == "" ||
        obj.formData.phone == null ||
        obj.formData.password == null
      ) {
        showNotify({
          message: "手机号或密码不能为空！",
          className: "MySize",
        });
        return true;
      }
      const reg = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
      if (!reg.test(obj.formData.phone)) {
        showNotify({
          message: "请输入正确手机号",
          className: "MySize",
        });
        return true;
      }
      return false;
    };
    onMounted(() => {
      
      let token = localStorage.getItem("token");
      if (token != null && token != "" && token != undefined) {
        router.push("/home");
      }
    });
    return { getOut, ...toRefs(obj) };
  },
};
</script>
<style lang="less" scoped>
/deep/.van-notify,
.MySize {
  font-size: 35px !important;
  transform: scale(3);
}

.login_user {
  width: 686px;
  border-radius: 24px;
  background-color: #fff;
  border: 2px solid rgba(255, 255, 255, 1);
  margin: auto;
  box-sizing: border-box;
  padding: 10px 48px 40px 48px;
  .tips {
    width: 100%;
    height: 36px;
    opacity: 1;
    /** 文本1 */
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 35.67px;
    color: rgba(123, 135, 135, 1);
    span {
      color: rgba(61, 63, 67, 1);
    }
  }
  button {
    width: 590px;
    height: 88px;
    border-radius: 48px;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 43.9px;
    color: rgba(30, 33, 33, 1);
    border: none;
    outline: none;
    margin-top: 80px;
    margin-bottom: 48px;
    color: #fff;
    background: linear-gradient(
      90deg,
      rgba(65, 167, 250, 1) 0%,
      rgba(180, 145, 250, 1) 100%
    );
  }
  .wjmm {
    width: 100%;
    text-align: right;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 35.67px;
    color: rgba(61, 63, 67, 1);
    margin: 24px 0 64px 0;
  }
  .input_box {
    width: 100%;
    height: 156px;
    margin-top: 60px;
    input {
      width: 100%;
      font-size: 28px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 88px;
      color: rgba(159, 171, 171, 1);
      text-align: left;
      box-sizing: border-box;
      padding-left: 32px;
      border-radius: 44px;
      outline: none;
      border: none;
      background-color: rgba(235, 239, 242, 0.5);
    }
    .title {
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 44px;
      color: rgba(30, 33, 33, 1);
      text-align: left;
      margin-bottom: 20px;
    }
  }
}
</style>
