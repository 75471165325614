import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible';
// 1. 引入你需要的组件
import { Button,Swipe, SwipeItem,Toast,Notify,PullRefresh,Uploader,Card,RadioGroup,Radio   } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
import i18n from '@/language/';
import dataV from '@jiaminghi/data-view'

// 引入方式一
createApp(App).use(store).use(router).use(Button).use(Swipe).use(SwipeItem).use(Toast)
.use(Notify)
.use(PullRefresh)
.use(Uploader)
.use(RadioGroup)
.use(Radio)
.use(Card)
.use(i18n)
.use(dataV)
.mount('#app')
