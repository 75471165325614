<template>
  <div class="login_box">
    <div class="login_class">
      <div @click="lglick">
        <span class="cActive">注册</span>
      </div>
      <!-- <div @click="upClick">
        <span :class="lgOrRg == 3 ? 'cActive' : ''">忘记密码</span>
      </div> -->
      <!-- <div @click="RgClick">
        <span :class="lgOrRg == 2 ? 'cActive' : ''">注册</span>
      </div> -->
    </div>
    <Register  />
  </div>
</template>

<script>
import LoginUser from "@/components/LoginUser";
import Register from "@/components/Register";
import updatepwd from "@/components/updatepwd";
import { reactive, toRefs, onMounted } from "vue";
export default {
  components: { LoginUser, Register, updatepwd },
  setup() {
    const obj = reactive({
      lgOrRg: 1,
    });
    const lglick = () => {
      obj.lgOrRg = 1;
    };
    const RgClick = () => {
      obj.lgOrRg = 2;
    };
    const upClick = () => {
      obj.lgOrRg = 3;
    };

    onMounted(() => {
      let url = window.location.href;
      if (url.indexOf("invitationCode") != -1) {
        obj.lgOrRg = 2;
      }
    });

    return { ...toRefs(obj), lglick, RgClick, upClick };
  },
};
</script>
<style lang="less" scoped>
.login_box {
  width: 100%;
  height: auto;
  background: linear-gradient(rgba(156, 210, 255, 0.3), #f8f8fa);
  box-sizing: border-box;
  padding-top: 40px;
  .login_class {
    width: 686px;
    height: 120px;
    display: flex;
    margin: auto;
    justify-content: space-around;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 43.9px;
    color: rgba(30, 33, 33, 1);
    div {
      line-height: 120px;
      .cActive {
        border-bottom: 6px solid rgba(240, 214, 75, 1);
      }
    }
  }
}
</style>
