<template>
  <el-config-provider>
    <router-view v-if="isRouterAlive" />
  </el-config-provider>
</template>
<script>

import { nextTick, provide, ref } from "vue";
export default {
  setup() {
    const isRouterAlive = ref(true);
    const reload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    };
    provide("reload", reload);
    return {
      isRouterAlive,
      reload,
    };
  },
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html,
body {
  // background-color: #f8f8fa;
  overflow: auto;
}
*{
  margin: 0;padding: 0;
}
</style>
