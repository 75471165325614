<template>
  <Header v-if="headShow" :isShow="isShow">{{ title }}</Header>
  <Bj/>
  <router-view></router-view>
  <Floor />
</template>
<script>
import Floor from "@/components/Floor.vue";
import Header from "@/components/header.vue";
import { useRouter } from "vue-router";
import { watch, ref,onMounted } from "vue";
import Bj from "@/components/bj.vue";
export default {
  components: { Floor, Header,Bj },
  setup() {
    const router = useRouter();
    let isShow = ref(false);
    let headShow = ref(false);
    let title = ref("七宝AI矩阵");
    watch(
      () => router.currentRoute.value.path,
      (newValue, oldValue) => {
        if(!headShow.value){
          return false;
        }
        switch (newValue) {
          case "/home":
            title.value = "首页";
            isShow.value = false;
            break;
          case "/productlist":
            title.value = "热销榜单";
            isShow.value = true;
            break;
          case "/myteam":
            title.value = "团队管理";
            isShow.value = true;
            break;
          case "/productlist":
            title.value = "我的收益";
            isShow.value = true;
            break;
          case "/income":
            title.value = "我的收益";
            isShow.value = true;
            break;
          case "/carry":
            title.value = "我的提现";
            isShow.value = true;
            break;
          case "/carryrecord":
            title.value = "提现记录";
            isShow.value = true;
            break;
          case "/treasure":
            title.value = "AI矩阵";
            isShow.value = false;
            break;
          case "/detent":
            title.value = "我的团队";
            isShow.value = false;
            break;
          case "/my":
            title.value = "个人中心";
            isShow.value = false;
            break;
          case "/my/profit":
            title.value = "全网分润";
            isShow.value = true;
            break;
          case "/integral":
            title.value = "积分广告";
            isShow.value = true;
            break;
          case "/integral":
            title.value = "积分广告";
            isShow.value = true;
            break;
          case "/myorder":
            title.value = "订单记录";
            isShow.value = true;
            break;
          case "/imgText":
            title.value = "项目简介";
            isShow.value = true;
            break;
          case "/teamcarryrecord":
            title.value = "团队提现记录";
            isShow.value = true;
            break;
          case "/myinvite":
            title.value = "专属邀请码";
            isShow.value = true;
            break;
          case "/ai":
            title.value = "七宝AI";
            isShow.value = false;
            break;
          case "/shop":
            title.value = "商城";
            isShow.value = false;
            break;
          case "/shopdetail":
            title.value = "详细";
            isShow.value = true;
            break;
          case "/myaddress":
            title.value = "地址管理";
            isShow.value = true;
            break;

          default:
            title.value = "AI矩阵图";
            isShow.value = false;
            break;
        }
      },
      { immediate: true }
    );
    const init = () => {
      var ua = window.navigator.userAgent.toLowerCase();
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf("micromessenger") > -1) {
        headShow.value = false;
      } else {
        headShow.value = true;
      }
    };
    onMounted(()=>{
      init();
    })
    return { isShow, title, headShow };
  },
};
</script>
