<template>
  <div class="login_user">
    <div class="input_box">
      <div class="title">手机号</div>
      <input
        class="pt_input"
        type="number"
        v-model="formData.phone"
        placeholder="请输入手机号"
      />
    </div>
    <!-- <div class="input_box">
      <div class="title">验证码</div>
      <div class="yzm_input">
        <input type="password" placeholder="请输入验证码" />
        <div v-if="djsShow" class="yzm_but" @click="gbYzm">发送验证码</div>
        <div v-else="djsShow" class="yanshi_but" @click="setYzm">{{ djs }}s</div>
      </div>
    </div> -->
    <div class="input_box">
      <div class="title">密码</div>
      <input
        class="pt_input"
        type="password"
        v-model="formData.password"
        placeholder="请输入密码"
      />
    </div>
    <div class="input_box">
      <div class="title">邀请码</div>
      <input
        class="pt_input"
        type="text"
        v-model="formData.topInvitationCode"
        placeholder="请输入邀请码"
      />
    </div>
    <button @click="getOut">立即注册</button>
    <div class="tips">注册及接受 <span>用户协议</span> 及 <span>隐私授权</span></div>
  </div>
  <slideVerify v-if="yzmShow" @gbYzm="gbYzm" @daoji="daoji" />
</template>
<script>
import { registerApi } from "@/api/getData";
import slideVerify from "./slideVerify.vue";
import { reactive, toRefs, onMounted,inject } from "vue";
import { showSuccessToast, showFailToast } from "vant";
import { showNotify } from "vant";
import { useRouter } from "vue-router";
export default {
  components: { slideVerify },
  setup() {
    const router = useRouter();
    const reload = inject("reload");
    const obj = reactive({
      yzmShow: false,
      djs: 60,
      djsShow: true,
      formData: {
        phone: "",
        password: "",
        topInvitationCode: "88888888",
      },
    });
    const gbYzm = () => {
      obj.yzmShow = !obj.yzmShow;
    };
    const daoji = () => {
      showSuccessToast("发送成功！");
      obj.yzmShow = false;
      obj.djsShow = false;
      var pd = setInterval(() => {
        obj.djs = obj.djs - 1;
        if (obj.djs <= 0) {
          clearInterval(pd);
          obj.djsShow = true;
          obj.djs = 60;
        }
      }, 1000);
    };

    const getOut = async () => {
      if (pdNull()) {
        return false;
      }
      try {
        let res = await registerApi(obj.formData);
        
        if (res.data.code == 200) {
          showNotify({ type: "success", message: "注册成功" });
          // window.location.href = "https://seven.wxc1997cheshi.top/#/login";
          router.push("/download")
          // reload();
        } else {
          showNotify({
            message: res.data.msg,
          });
        }
      } catch (error) {
        showNotify({
          message: error,
        });
      }
    };
    const pdNull = () => {
      if (
        obj.formData.phone == undefined ||
        obj.formData.password == undefined ||
        obj.formData.topInvitationCode == undefined ||
        obj.formData.phone == "" ||
        obj.formData.password == "" ||
        obj.formData.topInvitationCode == "" ||
        obj.formData.phone == null ||
        obj.formData.password == null ||
        obj.formData.topInvitationCode == null
      ) {
        showNotify({
          message: "手机号、密码、邀请码不能为空！",
        });
        return true;
      }
      const reg = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
      if (!reg.test(obj.formData.phone)) {
        showNotify({
          message: "请输入正确手机号",
        });
        return true;
      }
      return false;
    };

    onMounted(() => {
      let url = window.location.href;
      if(url.indexOf('invitationCode')!=-1){
        obj.lgOrRg = 2;
        obj.formData.topInvitationCode = url.split('invitationCode=')[1];
      }
    });
    return { ...toRefs(obj), gbYzm, daoji,getOut };
  },
};
</script>
<style lang="less" scoped>
.login_user {
  width: 686px;
  //   height: 800px;
  border-radius: 24px;
  background-color: #fff;
  border: 2px solid rgba(255, 255, 255, 1);
  margin: auto;
  box-sizing: border-box;
  padding: 10px 48px 20px 48px;
  .tips {
    width: 100%;
    height: 36px;
    opacity: 1;
    /** 文本1 */
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 35.67px;
    color: rgba(123, 135, 135, 1);
    span {
      color: rgba(61, 63, 67, 1);
    }
  }
  button {
    width: 590px;
    height: 88px;
    border-radius: 48px;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 43.9px;
    color: rgba(30, 33, 33, 1);
    border: none;
    outline: none;
    margin-bottom: 20px;
    color: #fff;
    margin-top: 70px;
    background: linear-gradient(
      90deg,
      rgba(65, 167, 250, 1) 0%,
      rgba(180, 145, 250, 1) 100%
    );
  }
  .input_box {
    width: 100%;
    height: 126px;
    margin-top: 60px;
    .yzm_input {
      width: 100%;
      height: 88px;
      display: flex;
      justify-content: space-between;
      line-height: 88px;
      .yanshi_but {
        width: 32%;
        color: #fff;
        background: #bdbdbd;
        font-size: 28px;
        border-radius: 44px;
      }
      .yzm_but {
        width: 32%;
        color: #fff;
        background: linear-gradient(
          90deg,
          rgba(65, 167, 250, 1) 0%,
          rgba(180, 145, 250, 1) 100%
        );
        font-size: 28px;
        border-radius: 44px;
      }
      input {
        padding-left: 32px;
        border-radius: 44px;
        outline: none;
        border: none;
        background-color: rgba(235, 239, 242, 0.5);
        width: 60%;
        font-size: 28px;
        font-weight: 400;
      }
    }
    .pt_input {
      width: 100%;
      font-size: 28px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 88px;
      color: rgba(159, 171, 171, 1);
      text-align: left;
      box-sizing: border-box;
      padding-left: 32px;
      border-radius: 44px;
      outline: none;
      border: none;
      background-color: rgba(235, 239, 242, 0.5);
    }
    .title {
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 44px;
      color: rgba(30, 33, 33, 1);
      text-align: left;
      margin-bottom: 20px;
    }
  }
}
</style>
