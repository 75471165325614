<template>
  <div class="header_zw"></div>
  <div class="header_box"><i v-if="isShow" @click="gototop" class="iconfont icon-right-1-copy"></i> <slot></slot></div>
</template>

<script>
import router from '@/router';
import { watch, ref } from "vue";
export default {
  props: ["isShow"],
  setup(props, context) {
    let isShow = ref(props.isShow);
    const gototop = () => {
      window.history.go(-1);
    };
    watch(()=>props.isShow,(newValue,oldValue)=>{
      isShow.value = newValue;
    })
    return {isShow,gototop};
  },
};
</script>
<style lang="less" scoped>
.header_zw{
  width: 100%;
  height: 70px;
}
.header_box {
  width: 100%;
  height: 70px;
  line-height: 70px;
  z-index: 2000000000;
  font-size: 35px;
  position: fixed;
  top:0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #FFF;
  color:#171717;
  i {
    position: absolute;
    left: 20px;
    transform: rotate(180deg);
    font-size: 35px;
    color: #171717;
  }
}
</style>
