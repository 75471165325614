<template>
  <div class="zhezao" @click="zGbYzm"></div>
  <i class="iconfont icon-guanbi My_guanbi" @click="zGbYzm"></i>
  <div class="silde_box">
    <slide-verify
      ref="block"
      :slider-text="text"
      :accuracy="accuracy"
      @again="onAgain"
      @success="onSuccess"
      @fail="onFail"
      @refresh="onRefresh"
    ></slide-verify>
    <button class="silde_but" @click="handleClick">点击刷新</button>
  </div>
</template>
<script>
import SlideVerify from "vue3-slide-verify";
import "vue3-slide-verify/dist/style.css";
import { ref } from "vue";
export default {
  components: { SlideVerify },
  emits: ["gbYzm"],
  setup(props, context) {
    const msg = ref("");
    const block = ref();
    const onAgain = () => {
      msg.value = "检测到非人为操作的哦！ try again";
      // 刷新
      block.value?.refresh();
    };

    const onSuccess = (times) => {
      msg.value = `login success, 耗时${(times / 1000).toFixed(1)}s`;
      context.emit("daoji")
    };

    const onFail = () => {
      msg.value = "验证不通过";
    };

    const onRefresh = () => {
      msg.value = "点击了刷新小图标";
    };

    const handleClick = () => {
      // 刷新
      block.value?.refresh();
      msg.value = "";
    };

    const zGbYzm = () => {
      context.emit("gbYzm");
    };

    return {
      block,
      msg,
      text: "向右滑动拼图->",
      accuracy: 1,
      onAgain,
      onSuccess,
      onFail,
      onRefresh,
      handleClick,
      zGbYzm,
    };
  },
};
</script>
<style lang="less" scoped>
.My_guanbi {
  position: absolute;
  top: 60%;
  left: 50%;
  color: #fff;
  z-index: 200000;
  font-size: 60px;
  margin-left: -30px;
}
.zhezao {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
}
.silde_box {
  z-index: 30;
  position: fixed;
  top: 20%;
  left: 50%;
  width: 580px;
  margin-left: -290px;
  height: auto;
  background-color: #fff;
  font-size: 30px;
  overflow: hidden;
  .silde_but {
    margin: 20px auto;
    width: 100%;
    height: 80px;
    border: none;
  }
  /deep/.slide-verify-slider {
    width: 99.5% !important;
    height: 80px !important;
    line-height: 80px;
    .slide-verify-slider-mask {
      height: 80px !important;
      line-height: 80px;
    }
  }
  /deep/.slide-verify-slider-mask-item {
    width: 100px !important;
    height: 80px !important;
  }
}
</style>
