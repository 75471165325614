<template>
  <div class="download_box">
    <img class="lsxq" src="@/assets/lsxq.png" alt="" />
    <img class="csxq" src="@/assets/csxq.png" alt="" />
    <!-- <img class="jqr" src="@/assets/jqr.png" alt="" /> -->
  </div>
</template>

<script>

</script>
<style lang="less" scoped>
.download_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #0c0833;
  background-image: url("@/assets/xx.png");
  background-size: 100% 100%;
  .txt {
    width: 100%;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: 3%;
    left: 0;
    font-size: 25px;
  }
  .tips {
    width: 100%;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    position: absolute;
    bottom: 10%;
    left: 0;
    font-size: 25px;
  }
  .anzuo {
    width: 500px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #0c0833;
    position: absolute;
    top: 60%;
    left: 50%;
    margin-left: -250px;
    background-color: #fff;
    border-radius: 5px;
    font-size: 40px;
    div {
      position: absolute;
      bottom: -30px;
      left: -50%;
      width: 200%;
      height: 30px;
      font-size: 30px;
      color: #fff;
    }
  }
  .ios_h5 {
    width: 500px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -250px;
    border: 1px solid #fff;
    border-radius: 5px;
    font-size: 40px;
  }
  .title {
    position: absolute;
    top: 40%;
    left: 0%;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 50px;
    letter-spacing: 4px;
  }
  .jqr {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 240px;
    margin-left: -120px;
  }
  .csxq {
    position: absolute;
    top: 40%;
    right: 0;
    width: 300px;
  }
  .lsxq {
    position: absolute;
    top: -100px;
    left: 0;
    width: 300px;
  }
}
</style>
