import { createI18n } from 'vue-i18n';
const i18n = createI18n({
    legacy: false, // 让 setup 函数可以通过 t 访问
    globalInjection: true, // 让 template 可以像 vue2 那样使用 $t 来访问
    locale: 'zh',
    messages: {
        'zh': {
            imgUrl: "https://saveadmin.wxc1997cheshi.top",
        },
        'en': {
            imgUrl: "https://saveadmin.wxc1997cheshi.top",
        }
    }
})

export default i18n 